/**
 * Combined by jsDelivr.
 * Original files:
 * - /npm/jquery@3.6.0/dist/jquery.min.js
 * - /npm/bootstrap@3.3.7/dist/js/bootstrap.min.js
 * - /npm/imagesloaded@4.1.4/imagesloaded.pkgd.min.js
 * - /npm/jquery.appear@1.0.1/jquery.appear.min.js
 * - /gh/davist11/jQuery-One-Page-Nav@22b0153/jquery.nav.min.js
 * - /npm/smoothscroll-for-websites@1.4.8/SmoothScroll.min.js
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
